/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials } = useGLTF("/wwrp_bramble.glb");
  return (
    <group ref={group} {...props} dispose={null}>
      <group position={[26.11, 89.2, 18.14]}>
        <group
          position={[-26.06, 30.94, -17.97]}
          rotation={[0.35, 0.24, -0.08]}
        >
          <group position={[0, -113.47, 39.48]} rotation={[-0.34, -0.25, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.bag.geometry}
              material={nodes.bag.material}
              position={[5.88, 85.16, 22.94]}
              material-color="#7B8F88"  
              material-roughness="0.5" 
              material-metalness="0.3"                
            />
          </group>
          <group position={[0, -113.47, 39.48]} rotation={[-0.34, -0.25, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.main.geometry}
              material={nodes.main.material}
              position={[25.74, 87.11, 3.92]}
              material-color="#4B5A5E"  
              material-roughness="0.2" 
              material-metalness="0.3"               
            />
          </group>
          <group position={[0, -113.47, 39.48]} rotation={[-0.34, -0.25, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.highlight.geometry}
              material={nodes.highlight.material}
              position={[15.65, 105.77, 5.4]}
              material-color="#CC7F27"  
              material-roughness="0.2"
              material-metalness="0.5"                
            />
          </group>
          <group position={[0, -113.47, 39.48]} rotation={[-0.34, -0.25, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.contrast.geometry}
              material={nodes.contrast.material}
              position={[28.14, 78.65, 8.7]}
              material-color="#CCCCCC"  
              material-roughness="0.2"
              material-metalness="0.2"                
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/wwrp_bramble.glb");

