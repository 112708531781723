import * as React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import FeatherIcon from 'feather-icons-react';
import * as THREE from 'three'
import { motion } from 'framer-motion';
import { Suspense, useRef } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'
import { Html, OrbitControls, MeshReflectorMaterial, Environment, Loader } from '@react-three/drei'
import { EffectComposer, Bloom } from '@react-three/postprocessing'
import Bramble from '../components/bramble'


function Model() {
  const ref = useRef()
  return (
    <Suspense fallback={null}>   
        <group position-y={0} dispose={null}>
          <Bramble scale={0.06} position={[0, -5, 0]} rotation={[0, Math.PI * 1.7, 0]}/>
        </group>         
  </Suspense>
  )
}

const BramblePage = () => (
  
  <Layout>
    

    <Helmet>
      <body className="body-yellow"></body>
    </Helmet>

<section className="h-screen flex items-end content-end justify-between">

      <motion.div 
        initial={{x: 50, opacity: 0}}      
        animate={{x: 0, opacity: 1 }}
        transition={{duration: 0.5, type: "spring", ease: 'easeOut', bounce: 0.4  }}       
        className="absolute w-full p-5 sm:mb-5 z-50 flex items-end content-end justify-between">  

        <div>
        <a href="/work#play">
          <FeatherIcon icon="corner-up-left" size="36" stroke="#222" className="mb-6"/>   
        </a>        

        <h1 className="display-1 mb-2">Bramble by Ashley Wood</h1>
        <motion.hr 
          className="mb-2"
          initial={{width: 0}}
          animate={{width: "100%"}}
          transition={{duration: 1.5}}                  
        /> 
        <p className="display-3">C4D // R3F // Click &amp; drag to rotate</p>
        </div>         

      </motion.div>  


      <Canvas shadows dpr={[1, 2]} camera={{ position: [0, 10, 20], fov: 40 }}> 
      <fog attach="fog" args={['#DBD3AD', 0, 90]} />
      <color attach="background" args={['#DBD3AD']} />
          <Model  />  
          <mesh rotation={[-Math.PI / 2, 0, 0]} position={[0, -5, 0]}>
          <planeGeometry args={[170, 170]} />
          <MeshReflectorMaterial
            blur={[400, 100]}
            resolution={1024}
            mirror={0.75}
            mixBlur={1}
            opacity={2}
            depthScale={1.1}
            minDepthThreshold={0.4}
            maxDepthThreshold={1.25}
            roughness={0.5}
            color="#DBD3AD"
          />
        </mesh>   
        <mesh receiveShadow rotation-x={-Math.PI / 2} position={[0, -4.9, 0]}>
          <planeGeometry args={[100, 100]} />
          <shadowMaterial transparent color="black" opacity={0.4} />
        </mesh>
  
          <spotLight position={[-10, 30, 10]} angle={0.25} penumbra={0.3} castShadow intensity={0.01} shadow-bias={-0.0001} />            
        <OrbitControls
        autoRotate
        autoRotateSpeed={0.6}
        enablePan={false}
        enableZoom={false}
        minPolarAngle={Math.PI / 2.2}
        maxPolarAngle={Math.PI / 2.2}
      />     
            <Environment preset="warehouse" />
      </Canvas>
      <Loader />  

  </section>
 
  </Layout>
)


export default BramblePage
